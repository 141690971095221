import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      validAddress: true,
    };
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
      imageResponse: "posts/getImageResponse",
    }),
    companyNameError() {
      const errors = [];
      if (this.isAssociate && !this.$v.userDetail.company_name.$dirty)
        return errors;
      !this.isAssociate && !this.$v.userDetail.company_name.required &&
          errors.push(this.$t("profile.validations.companyNameIsRequired"));
      !this.$v.userDetail.company_name.maxLength &&
        errors.push(this.$t("profile.validations.companyNameMaxLength"));
      return errors;
    },

    companyEmailError() {
      const errors = [];
      if (this.isAssociate && !this.$v.userDetail.company_email.$dirty)
        return errors;
      !this.isAssociate &&
        !this.$v.userDetail.company_email.required &&
        errors.push(this.$t("profile.validations.companyEmailIsRequired"));
      !this.isAssociate &&
        !this.$v.userDetail.company_email.email &&
        errors.push(this.$t("profile.validations.companyEmailIsInvalid"));
      return errors;
    },

    companyPhoneError() {
      const errors = [];
      if (this.isAssociate && !this.$v.userDetail.company_phone.$dirty)
        return errors;
      !this.isAssociate &&
        !this.$v.userDetail.company_phone.required &&
        errors.push(this.$t("profile.validations.companyPhoneIsRequired"));
      !this.isAssociate &&
        !this.$v.userDetail.company_phone.usFormat &&
        errors.push(this.$t("profile.validations.companyPhoneIsInvalid"));
      return errors;
    },

    representativeNameError() {
      const errors = [];
      if (!this.$v.userDetail.representative_name.$dirty) return errors;
      !this.$v.userDetail.representative_name.required &&
        errors.push(this.$t("profile.validations.representativeNameIsRequired"));
      !this.$v.userDetail.representative_name.maxLength &&
        errors.push(this.$t("profile.validations.representativeNameMaxLength"));
      return errors;
    },

    representativePhoneError() {
      const errors = [];
      if (!this.$v.userDetail.representative_phone.$dirty) return errors;
      !this.$v.userDetail.representative_phone.required &&
        errors.push(
          this.$t("profile.validations.representativePhoneIsRequired")
        );
      !this.$v.userDetail.representative_phone.usFormat &&
        errors.push(
          this.$t("profile.validations.representativePhoneIsInvalid")
        );
      return errors;
    },

    zipCodeError() {
      const errors = [];
      if (this.isAssociate && !this.$v.userDetail.zip_code.$dirty)
        return errors;
      !this.isAssociate &&
        !this.$v.userDetail.zip_code.required &&
        errors.push(this.$t("profile.validations.zipIsRequired"));
      return errors;
    },

    stateError() {
      const errors = [];
      if (this.isAssociate && !this.$v.userDetail.state.$dirty) return errors;
      !this.isAssociate &&
        !this.$v.userDetail.state.required &&
        errors.push(this.$t("profile.validations.stateIsRequired"));
      return errors;
    },

    cityError() {
      const errors = [];
      if (this.isAssociate && !this.$v.userDetail.city.$dirty) return errors;
      !this.isAssociate &&
        !this.$v.userDetail.city.required &&
        errors.push(this.$t("profile.validations.cityIsRequired"));
      return errors;
    },
  },

  methods: {
    ...mapActions({
      updateProfile: "user/updateProfile",
      uploadImage: "posts/uploadImage",
    }),
    addressHandler(payload) {
      this.userDetail.address = payload.address;
      this.userDetail.state = payload.state;
      this.userDetail.zip_code = payload.zip;
      this.validAddress = payload.valid;
    },
    async update() {
      this.$v.$touch();
      if (!this.$v.$invalid && this.validAddress) {
        this.loading = true;
        try {
          const payload = { ...this.userDetail };

          if (payload.representative_phone) {
            payload.representative_phone = payload.representative_phone.replace(
              /[^0-9]/gi,
              ""
            );
          }

          if (payload.company_phone) {
            payload.company_phone = payload.company_phone.replace(
              /[^0-9]/gi,
              ""
            );
          }

          if(this.image && this.user.user_type == 3) {
            payload.media_id = this.image ? this.image?.id : '';
            payload.media_tag = this.image ? this.image?.tag : '';
          }
          await this.updateProfile(payload);
        } catch (error) {
          // TODO: add toastify here
          console.log("");
        } finally {
          this.loading = false;
        }
      }
    },
  },
};
